@import '../../assets/styles/base.scss';

@include pc {
    .banner-container {
        position: relative;
        height: auto;
        overflow: hidden;
        background: #F5F5F5;

        &.fix-height {
            .banner {
                margin-top: $header-height;

                .left {
                    height: 448px - $header-height;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding-block: 0;
                }
            }
        }

        .section-container {
            padding-block: 0;
        }

        .banner {
            position: relative;
            display: flex;
            justify-content: space-between;
            z-index: 1;

            .left {
                padding-block: $header-height + 80px 80px;
            }

            p {
                margin-top: 16px;
                line-height: 28px;
                max-width: 620px;
            }

            .button {
                margin-top: 40px;
            }

            .icon-animate {
                display: flex;
            }

            .banner-icon {
                align-self: flex-end;
            }
        }

        .banner-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@include phone {
    .banner-container {
        position: relative;
        height: auto;
        overflow: hidden;
        background: #F5F5F5;
        padding: 104px 0 0;

        .section-container {
            padding-block: 0;
        }

        .banner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            z-index: 1;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-block: 0;
            }

            p {
                margin-top: 12px;
                line-height: 20px;
                max-width: 100%;
                text-align: center;
            }

            svg {
                width: 100%;
                height: 180px;
            }

            .button {
                margin: 20px auto 40px;
            }

            .banner-icon {
                align-self: flex-end;
            }
        }

        .banner-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: #F5F5F5;
        }
    }
}