@import '../../assets/styles/base.scss';

.section-footer-bg {
    background: #000;
    color: #F5F5F5;

    &.section-container.large {
        @include pc {
            padding-block: 80px 40px;
        }

        @include phone {
            padding: 48px 20px;
        }
    }

    footer {
        position: relative;
        display: flex;
        flex-direction: column;

        @include phone {
            padding-inline: 0;
        }


        .top {

            .left {
                .logo-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: -3px;

                    .logo {
                        width: 40px;
                        height: 40px;
                    }

                    .logo-text {
                        color: #FFF;
                        font-style: normal;
                        font-weight: 600;
                        margin-left: 8px;
                    }
                }

                .text1 {
                    color: #F5F5F5;
                }

                .text2 {
                    color: #F5F5F5;
                }

                .text3 {
                    color: #F5F5F5;
                    opacity: 0.6000000238418579;
                }

                .text4 {
                    display: flex;
                    align-items: center;
                    color: #FFF;

                    img {
                        margin-right: 5px
                    }
                }
            }

            .right {
                .nav-item {
                    display: flex;
                    flex-direction: column;

                    .title {
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .sub-menu {
                        display: flex;
                        flex-direction: column;

                        .submenu-item {
                            color: rgba(255, 255, 255, 0.60);
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }

        }


        .divider {
            height: 1px;
            background-color: rgba(255, 255, 255, 0.18);
        }

        @include pc {
            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .left {

                    .logo-text {
                        font-size: 28px;
                    }

                    .text1 {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                        margin-top: 45px;
                    }

                    .text2 {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                        display: flex;
                        gap: 10px;
                    }

                    .text3 {
                        font-size: 14px;
                        margin-top: 8px;
                        max-width: 420px;
                    }

                    .text4 {
                        font-size: 14px;
                        margin-top: 56px;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    .nav-item {
                        width: 200px;

                        &+.nav-item {
                            margin-right: 16px;
                        }

                        .title {
                            color: #FFF;
                            font-size: 14px;
                            font-weight: 600;

                            &+.title {
                                margin-top: 8px;
                            }
                        }

                        .submenu {
                            display: flex;
                            flex-direction: column;

                            .submenu-item {
                                display: block;
                                color: rgba(255, 255, 255, 0.60);
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 400;
                                margin-top: 8px;

                                &:hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }

            .divider {
                margin-top: 24px;
            }

            .bottom {
                padding-top: 40px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: rgba(255, 255, 255, 0.6);

                .policy-link {
                    color: inherit;

                    &:hover {
                        color: #fff;
                    }


                    &+.policy-link {
                        margin-left: 24px;
                    }
                }
            }

        }

        @include phone {
            .top {
                .left {
                    .logo-box {
                        .logo {
                            width: 32px;
                        }
                        .logo-text {
                            font-size: 20px;
                            line-height: 24px;
                        }
                    }

                    .text1 {
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 500;
                        margin-top: $gap;
                        color: #fff;
                        opacity: 0.7;
                    }

                    .text2 {
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 500;
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        color: #fff;
                        opacity: 0.7;
                    }

                    .text3 {
                        font-size: 12px;
                        margin-top: 8px;
                        opacity: 0.4;
                        font-weight: 400;
                    }

                    .text4 {
                        font-size: 14px;
                        margin-top: $gap;
                        padding-bottom: $gap;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                        font-weight: 400;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .nav-item {
                        // flex: 1;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        margin-top: $gap;

                        .title {
                            color: #FFF;
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 8px;
                            line-height: 24px;
                        }

                        .submenu {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .submenu-item {
                                display: block;
                                color: rgba(255, 255, 255, 0.60);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }

            .divider {
                margin-top: 24px;
            }

            .bottom {
                padding-top: 24px;
                font-size: 14px;
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
                color: #FFF;
                opacity: 0.6;
                line-height: 24px;

                div {
                    display: flex;
                    width: 100%;
                    .policy-link {
                        width: 50%;
                        color: inherit;
                        text-align: left;
                    }
                    margin-bottom: $phone-gap;
                }

            }

        }

        .to-top {
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            cursor: pointer;

            @include phone() {
                right: 0px;
                top: 0px;
            }
        }
    }
}