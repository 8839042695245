@import '../../assets/styles/base.scss';

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes scaleUp {
    to {
        opacity: 1;
        transform: scale(1);
    }
}


@include pc {
    .disclaimer-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        animation: fadeIn 0.3s forwards;
        z-index: 999;

        &.hide {
            display: none;
        }

        .disclaimer {
            width: 800px;
            padding: 48px;
            background: #FFFFFF;
            border-radius: 24px;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            opacity: 0;
            transform: scale(0);
            animation: scaleUp 0.3s forwards;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 120px;
                height: 120px;
            }

            h3 {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                margin-top: 24px;
            }

            p {
                margin-block: 16px 24px;
            }

            button {
                height: 48px;
                padding-inline: 24px;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: #ffffff;
                }
            }
        }

        .close-btn {
            position: absolute;
            top: 24px;
            right: 24px;

            &:hover {
                cursor: pointer;

                path {
                    stroke: $primary-color;
                }
            }
        }
    }
}

@include phone {
    
    .disclaimer-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        animation: fadeIn 0.3s forwards;
        z-index: 999;
        padding-inline: 16px;

        &.hide {
            display: none;
        }

        .disclaimer {
            width: 100%;
            padding: 24px;
            background: #FFFFFF;
            border-radius: 24px;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            opacity: 0;
            transform: scale(0);
            animation: scaleUp 0.3s forwards;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 120px;
                height: 120px;
            }

            h3 {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                margin-top: 8px;
            }

            p {
                margin-block: 8px 16px;
            }

            button {
                padding: 14px 20px;
                font-size: 14px;
                line-height: 18px;

                &:hover {
                    color: #ffffff;
                }
            }
        }

        .close-btn {
            position: absolute;
            top: 24px;
            right: 24px;

            &:hover {
                cursor: pointer;

                path {
                    stroke: $primary-color;
                }
            }
        }
    }
}