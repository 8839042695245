@import '../../assets/styles/base.scss';

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        @include pc {
            width: 64px;
            height: 64px;
        }

        @include phone {
            width: 64px;
            height: 64px;
        }

        &+svg {
            margin-left: 30px;
        }
    }
}