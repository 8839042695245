@import '../../assets/styles/base.scss';

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "HarmonyOS Sans SC";
    background-color: #fff;
}

html,
body * {
    box-sizing: border-box;
    // background-color: #fff;
}


a {
    display: inline-block;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    text-transform: capitalize;
    margin-block: 0;
    margin-inline: 0;
}

// ant-design
.ant-form-item-has-error {
    .ant-input {
        border: 1px solid #E33C39;
    }
}

h1 {
    font-weight: 700;
    font-family: "HarmonyOS Sans SC";

    @include pc {
        font-size: 72px;
    }

    @include phone {
        font-size: 30px;
    }
}

h2 {
    font-weight: 500;
    font-family: "HarmonyOS Sans SC";

    @include pc {
        font-size: 48px;
    }

    @include phone {
        font-size: 24px;
    }
}

h3 {
    font-weight: 500;
    font-family: "HarmonyOS Sans SC";

    @include pc {
        font-size: 40px;
    }

    @include phone {
        font-size: 20px;
    }
}

h4 {
    font-weight: 500;
    font-family: "HarmonyOS Sans SC";

    @include pc {
        font-size: 20px;
    }

    @include phone {
        font-size: 16px;
    }
}

p {
    color: #565656;
    font-weight: 400;
    line-height: 140%;
    font-family: "HarmonyOS Sans SC";

    @include pc {
        font-size: 16px;
    }

    @include phone {
        font-size: 14px;
    }
}


.title-star-around {
    @include pc {

        display: flex;
        align-items: center;
        justify-content: center;

        &::before,
        &::after {
            display: inline-block;
            content: ' ';
            width: 20px;
            height: 25px;
            background: center center / contain no-repeat url('../images/global/title-star.svg');
        }

        &::before {
            margin-right: 24px;
        }

        &::after {
            margin-left: 24px;
        }
    }
}

//ant-design override 

// form-item
.ant-form-item {
    &.ant-form-item-has-error {
        .ant-input {
            border: 1px solid $danger-color;
        }
    }
}

.ant-form-item-explain {

    .ant-form-item-explain-error {
        color: $danger-color ;

        @include pc {
            font-size: 16px;
        }

        @include phone {
            font-size: 14px;
        }

        line-height:1.4;
    }
}

// input
.ant-input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 12px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &textarea {
        height: auto;
        padding: 12px 24px;
    }

    &:focus,
    &:hover {
        border: 1px solid $primary-color;
    }
}

// submit button
.ant-btn.ant-btn-primary[type="submit"] {
    color: #FFF;
    border-radius: 24px;
    padding: 14px 40px;
    line-height: 1;
    height: auto;
    background: #000;
    border: none;
    box-shadow: none;
    font-family: "HarmonyOS Sans SC";

    &:not(:disabled):not(.ant-btn-disabled) {

        &:hover,
        &:active {
            background: #333333;
            color: #FFF;
            border: none;
        }
    }

    span {
        color: #FFF;
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
}

.ant-btn.ant-upload-list-item-action {
    background-color: transparent;
}

.upload {
    a {
        color: #565656;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;
        margin-right: 8px;
    }

    .file-types {
        margin-left: -8px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }
}

.submit-result {
    &.success {
        color: $primary-color;
    }

    &.danger {
        color: $danger-color;
    }
}

// pagination 
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;

    .ant-pagination {
        margin-top: 28px;
        display: flex;
        align-items: center;

        .ant-pagination-item {
            width: 32px;
            height: 32px;
            border-radius: 16px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.06)
            }

            &.ant-pagination-item-active {
                background-color: $primary-color;
                border: none;

                a {
                    color: #fff;
                }
            }
        }

        .ant-pagination-item-link {
            border-radius: 16px;

            .ant-pagination-item-container {
                .ant-pagination-item-link-icon {
                    color: $primary-color;
                }
            }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            .anticon {
                width: 32px;
                height: 32px;
                padding: 3px;

                svg {
                    display: none;
                }

                &::after {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    content: ' ';
                    background-size: contain;

                    svg {
                        display: none;
                    }
                }
            }
        }

        .ant-pagination-prev {
            .anticon::after {
                background-image: url('../images/pagination/arrow-left-s.svg');
            }
        }

        .ant-pagination-next {

            .anticon::after {
                background-image: url('../images/pagination/arrow-right-s.svg');
            }
        }

        .ant-pagination-disabled {
            &.ant-pagination-prev {
                .anticon::after {
                    background-image: url('../images/pagination/arrow-left-s-disabled.svg');
                }
            }

            &.ant-pagination-next {
                .anticon::after {
                    background-image: url('../images/pagination/arrow-right-s-disabled.svg');
                }
            }
        }
    }
}


// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_SansSC_Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_SansSC_Bold.ttf') format('truetype');
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_Sans_Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_SansSC_Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_Sans_Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: "HarmonyOS Sans SC";
//     src: url('../fonts/HarmonyOS_Sans_Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
// }

@font-face {
    font-family: 'HarmonyOS Sans SC';
    src: url('../fonts/woff/HarmonyOS_Sans_Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HarmonyOS Sans SC';
    src: url('../fonts/woff/HarmonyOS_Sans_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HarmonyOS Sans SC';
    src: url('../fonts/woff/HarmonyOS_Sans_Bold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}