@import '../../assets/styles/base.scss';

.section-container {
    width: 100%;
    @include pc {
        padding-inline: $section-bg-padding-inline;

    }
    @include phone {
        padding-inline: $phone-section-bg-padding-inline;
        background-color: #fff;
    }

    &.large {
        padding-block: 120px;
    }

    &.middle {
        padding-block: 40px;
    }

    &.small {
        padding-block: 20px 40px;
    }

    .section {
        max-width: $max-width;
        margin-inline: auto;
    }
}