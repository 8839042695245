@mixin phone() {
    @media screen and (max-width: 768px) {
        @content
    }
}

@mixin pc() {
    @media screen and (min-width: 768px) {
        @content
    }
}

@mixin beyond-max-width() {
    @media screen and (min-width: 1200px) {
        @content
    }
}

;

@mixin inner-max-width() {
    @media screen and (max-width: 1200px) {
        @content
    }
}

$primary-color: #304FF2;
$danger-color: #E33C39;
$phone-max-width: 768px;
$max-width: 1200px;
$section-bg-padding-inline: 20px;
$phone-section-bg-padding-inline: 24px;
$gap: 24px;
$header-height: 80px;
$phone-gap: 16px;
$default-bg-color: #fff;

@mixin width-limit {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1200px) {
        padding: 0 100px;
    }

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
}

@mixin bg-holder($content, $position) {
    background: $content, linear-gradient(180deg, #EAEAF1 0%, #C7D3FF 100%) $position / cover;
}

$header-height: 80px;
$phone-header-height: 64px;