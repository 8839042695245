@import '../../assets/styles/base.scss';

.header-container {

    .notice-bg {
        background-color: $primary-color;
        padding: 18px;

        .notice {
            max-width: $max-width;
            margin: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            gap: 8px;

            .left,
            .right {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #ffffff;
            }

            @include phone {
                font-size: 12px;

                .left {
                    flex: auto;

                    .conent {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .link-text {
                    display: none;
                }
            }

        }
    }

    @include pc {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: #FFFFFF;

        &.transparent {
            background-color: transparent;
        }

        transition: background-color 200ms ease-in-out;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

        &.open {
            height: auto;
        }

        header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: $max-width;
            margin: auto;

            .logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: auto;

                img {
                    width: 40px;
                    height: 40px;
                }

                span {
                    color: #000;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    margin-left: 8px;
                    font-size: 28px;
                }
            }

            .right {

                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    color: rgba(0, 0, 0, 0.80);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    transition: border-bottom 200ms;
                    display: flex;
                    align-items: center;
                    height: 80px;

                    &.has-children {
                        &::after {
                            content: ' ';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background-size: contain;
                            margin-top: 5px;
                            background-image: url('../../assets/images/header/arrow-down-s-line.svg');
                        }

                        &.active,
                        &:hover {

                            &::after {
                                margin-top: 5px;
                                background-image: url('../../assets/images/header/arrow-up-s-line.svg');
                            }
                        }
                    }

                    &.active,
                    &:hover {
                        color: #304FF2;
                    }
                }

                >a+a {
                    margin-left: 40px;
                }

                .contact-us {
                    height: auto;
                    border-radius: 100px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    display: inline-flex;
                    padding: 14px 24px;
                    align-items: center;
                    gap: 10px;
                    color: rgba(0, 0, 0, 0.80);
                    font-family: "HarmonyOS Sans SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    transition: background-color 200ms;

                    &:hover {
                        background-color: #000;
                        color: #ffffff;
                        border-bottom: none;
                    }

                    &:hover {
                        color: #fff;
                        background: $primary-color;
                        border: 1px solid $primary-color;
                    }
                }

                .menu-icon {
                    display: none;
                    width: 24px;
                    height: 24px;
                }
            }
        }

    }

    @include phone {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: #FFFFFF;

        display: flex;
        flex-direction: column;

        &.open {
            top: 0;
            z-index: 100;
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #ffffff;

            .mobile-menu {
                display: flex;
            }

            .menu-icon {
                width: 20px;
                height: 20px
            }
        }

        header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

            padding: 16px 24px;

            .logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: auto;

                img {
                    width: 24px;
                    height: 24px;
                }

                span {
                    color: #000;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    margin-left: 8px;

                    @include pc {
                        font-size: 28px;
                    }

                    @include phone {
                        font-size: 20px;
                    }
                }
            }

            .right {

                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    color: rgba(0, 0, 0, 0.80);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    transition: border-bottom 200ms;
                    display: flex;
                    align-items: center;
                    height: 80px;

                    &.has-children {
                        &::after {
                            content: ' ';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background-size: contain;
                            margin-top: 5px;
                            background-image: url('../../assets/images/header/arrow-down-s-line.svg');
                        }

                        &.active,
                        &:hover {

                            &::after {
                                margin-top: 5px;
                                background-image: url('../../assets/images/header/arrow-up-s-line.svg');
                            }
                        }
                    }

                    &.active,
                    &:hover {
                        color: $primary-color;
                    }
                }

                >a+a {
                    margin-left: 40px;
                }

                .contact-us {
                    height: auto;
                    border-radius: 100px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    background: #FFF;
                    display: inline-flex;
                    padding: 14px 24px;
                    align-items: center;
                    gap: 10px;
                    color: rgba(0, 0, 0, 0.80);
                    font-family: "HarmonyOS Sans SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    transition: background-color 200ms;

                    &:hover {
                        background-color: #000;
                        color: #ffffff;
                        border-bottom: none;
                    }

                    &:hover {
                        color: #fff;
                        background: $primary-color;
                        border: 1px solid $primary-color;
                    }
                }

                .menu-icon {
                    @include pc {
                        display: none;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .mobile-menu {
            background: #F6F7F9;
            flex: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 48px 40px;
            overflow: auto;

            .ant-menu {
                padding: 0 !important;
                margin: 0 !important;
                background: none;
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);

                .ant-menu-item {
                    padding: 0 !important;
                    border-radius: 0;
                    margin: 0 !important;
                    width: 100% !important;
                    height: auto !important;
                    border-top: 1px solid rgba(0, 0, 0, 0.10);

                    &:hover {
                        background: none;
                    }

                    .ant-menu-title-content {
                        padding-block: 18px !important;
                        line-height: 20px;
                        color: rgba(0, 0, 0, 0.80);
                        font-size: 16px;
                        font-weight: 500;
                    }

                }

                .ant-menu-submenu {
                    border-top: 1px solid rgba(0, 0, 0, 0.10);
                    border-radius: 0;

                    &.ant-menu-submenu-active {}

                    .ant-menu-submenu-title {
                        padding: 0 !important;
                        border-radius: 0;
                        margin: 0 !important;
                        width: 100% !important;
                        height: auto !important;

                        &:hover {
                            background: none;
                        }

                        .ant-menu-title-content {
                            padding-block: 18px !important;
                            color: rgba(0, 0, 0, 0.80);
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                        }

                        .ant-menu-submenu-arrow {}
                    }

                    .ant-menu-sub {
                        background: none;
                        border-bottom: none;

                        .ant-menu-item {
                            border-top: 0px solid rgba(0, 0, 0, 0.10);
                            color: rgba(0, 0, 0, 0.80);

                            .ant-menu-title-content {
                                padding-inline: 20px !important;
                                padding-block: 16px !important;
                                font-size: 14px;
                                line-height: 20px;
                                font-style: normal;
                                font-weight: 400;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }

            .contact-us {
                display: block;
                width: 100%;
                margin-top: 50px;
                padding: 14px 24px;
                align-items: center;
                gap: 10px;
                text-align: center;
                border-radius: 100px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #FFF;
                color: rgba(0, 0, 0, 0.80);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }

    }





}

// header menu popup (pc)
.menu-popup {
    width: 252px;
    // top: 88px !important;

    .ant-dropdown-menu {
        padding: 16px;

        .ant-dropdown-menu-item {
            padding: 8px 10px;

            &:hover {
                border-radius: 4px;
                background: #F1F4F9;
            }

            .menu-text {
                color: rgba(0, 0, 0, 0.80);
                font-size: 14px;
                font-weight: 400;

                &.active,
                &:hover {
                    color: $primary-color !important;
                }
            }
        }
    }
}