@import '../../assets/styles/base.scss';

@include pc {
    .button {
        display: inline-flex;
        padding: 18px 60px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #304FF2;
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-family: "HarmonyOS Sans SC";
        border: none;

        &:hover {
            background: rgba(48, 79, 242, 0.70);
        }
    }
}

@include phone {
    .button {
        display: inline-flex;
        padding: 12px 60px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #304FF2;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: "HarmonyOS Sans SC";
        border: none;

        &:hover {
            background: rgba(48, 79, 242, 0.70);
        }
    }
}