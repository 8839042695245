@import '../../assets/styles/base.scss';

@include pc {
    .home-page {

        .banner-bg {
            position: relative;
            padding-block: 160px;
            background-color: $default-bg-color;

            .banner {
                text-align: center;
                position: relative;
                z-index: 1;

                h1 {
                    text-transform: none;

                    &.banner-title-1 {
                        font-weight: normal;
                    }
                }

                p {
                    margin-top: 32px;
                }

                .button {
                    margin-top: 64px;
                }
            }

            #canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }

        .section1-bg {
            background: #FAFAFA;

            .section1 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    max-width: 560px;

                    p {
                        margin-top: $gap;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    gap: 20px;

                    >div {
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-around;
                        gap: 45px;

                        &:first-of-type {
                            padding-inline: 45px;
                        }

                        .item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .icon {
                                width: 72px;
                                height: 72px;
                            }

                            &.more {
                                &:hover {
                                    cursor: pointer;

                                    .icon {
                                        opacity: 0.7;
                                    }
                                }
                            }

                            .name {
                                color: #8A8A8E;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 20px;
                                margin-top: 12px;
                            }

                            &:nth-child(3) {
                                &::after {
                                    content: ' ';
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .section2 {
            .content {
                margin-block: 48px;
                gap: $gap;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: $gap * 2;
                    border-radius: 24px;
                    border: 1px solid #EBEBEB;

                    h3 {
                        margin-top: 48px;
                    }

                    p {
                        margin-top: $gap;
                    }
                }
            }
        }

        .section3-bg.large {
            padding-top: 0;

            .section3 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                >div {
                    max-width: 764px;

                    p {
                        margin-top: $gap;
                    }

                    .button {
                        margin-top: 64px;
                    }
                }
            }
        }

        .section4-bg {
            background: #FAFAFA;

            .content {
                margin-top: 64px;
                display: flex;
                flex-wrap: wrap;
                gap: 100px;

                .item {
                    width: calc(50% - 50px);
                    flex: auto;
                    height: 363px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .text {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    h3 {
                        margin-top: 40px;
                    }

                    p {
                        margin-top: $gap;
                    }
                }
            }
        }

        .section5-bg {
            background: #304FF2;
            text-align: center;
            padding-block: 108px;

            .section5 {
                position: relative;

                p {
                    font-size: 26px;
                    max-width: 820px;
                    margin-inline: auto;
                    color: rgb(255, 255, 255);
                    line-height: 32px;
                }

                .top,
                .bottom {
                    position: absolute;
                }

                .top {
                    left: 0;
                    top: -120px;
                }

                .bottom {
                    right: 0;
                    bottom: -120px;
                }
            }
        }

        .section6 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            >div:nth-child(1) {
                max-width: 500px;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: start;

                    h2 {
                        margin-top: 40px;
                    }

                    p {
                        margin-top: $gap;
                    }
                }
            }

            .form-wrapper {
                width: 50%;
                max-width: 550px;

                .row-1 {
                    width: 100%;
                    gap: $gap / 2;

                    .ant-space-item {
                        flex: auto;
                    }
                }

                .ant-form-item.submit {
                    .ant-form-item-control-input-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

@include phone {
    .home-page {

        .banner-bg {
            position: relative;
            min-height: auto;
            background-color: $default-bg-color;

            .banner {
                text-align: center;
                position: relative;
                z-index: 1;

                h1 {
                    text-transform: none;

                    &.banner-title-1 {
                        font-weight: normal;
                    }
                }
                
                p {
                    margin-top: 12px;
                }

                .button {
                    margin-top: 24px;
                }
            }

            #canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }

        .section1-bg {
            background: #FAFAFA;

            .section1 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .left {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-top: $phone-gap;
                    }
                }

                .content {
                    margin-top: 32px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    gap: 0px;

                    >div {
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-around;
                        gap: 0px;
                        width: 57.14%; // 4/7

                        &:first-of-type {
                            padding-inline: 0px;
                            width: 42.86%; //3/7
                        }

                        .item {
                            width: 40px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 8px;

                            .icon {
                                width: 36px;
                            }

                            .name {
                                color: #8A8A8E;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 14px;
                            }

                            &:nth-child(3) {
                                &::after {
                                    content: ' ';
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .section2 {
            .content {
                margin-block: 24px 0;
                gap: $phone-gap;

                .item {
                    padding: $phone-gap;
                    border-radius: 16px;
                    border: 1px solid #EBEBEB;

                    h3 {
                        margin-top: 0px;
                        float: left;
                        line-height: 44px;
                    }

                    img {
                        float: right;
                        width: 44px;
                    }

                    p {
                        margin-top: 0px;
                        padding-top: $phone-gap;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        clear: both;
                    }
                }
            }
        }

        .section3-bg {
            padding-top: 0;

            .section3 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                >div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &:first-child {
                        order: 2;
                    }

                    &:last-child {
                        order: 1;
                    }

                    max-width: 100%;

                    h2 {
                        margin-top: $gap;
                    }

                    p {
                        margin-top: $phone-gap;
                    }

                    .button {
                        margin-top: $gap;
                    }

                    img {
                        width: 140px;
                    }

                }
            }
        }

        .section4-bg {
            background: #FAFAFA;

            h2 {
                text-align: left;
                padding-bottom: $gap;
                border-bottom: 0.5px solid #DDD;
            }

            .content {
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                gap: 64px;

                .item {
                    width: 100%;
                    flex: auto;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: $gap;

                    img {
                        width: 100px;
                    }

                    h3 {
                        margin-top: 0px;
                    }

                    p {
                        margin-top: $phone-gap;
                    }

                    &:nth-child(even) {
                        div {
                            order: 2;
                        }

                        img {
                            order: 1;
                        }
                    }

                    &:nth-child(odd) {
                        div {
                            order: 1;
                        }

                        img {
                            order: 2;
                        }
                    }
                }
            }
        }

        .section5-bg {
            background: #304FF2;
            text-align: center;

            .section5 {
                position: relative;

                p {
                    max-width: 100%;
                    margin-inline: auto;
                    color: #ffffff;
                    line-height: 20px;
                    font-size: 16px;
                }

                .top,
                .bottom {
                    position: absolute;
                }

                .top {
                    width: 89px;
                    left: 0;
                    top: -40px;
                }

                .bottom {
                    width: 89px;
                    right: 0;
                    bottom: -40px;
                }
            }
        }

        .section6 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;

            >div:nth-child(1) {
                max-width: 100%;

                img {
                    float: right;
                    width: 80px;
                }

                h2 {
                    margin-top: 0px;
                }

                p {
                    margin-top: $phone-gap;
                    padding-right: 104px; // 80 + 24
                }
            }

            .form-wrapper {
                width: 100%;
                max-width: 100%;
                margin-top: $gap;

                .row-1 {
                    width: 100%;
                    gap: $gap / 2;

                    .ant-space-item {
                        flex: auto;
                    }
                }

                .ant-form-item {
                    margin-bottom: 8px;

                    .ant-input {
                        &::placeholder {
                            color: #8A8A8E;
                        }
                    }
                }

                .ant-form-item.submit {
                    .ant-form-item-control-input-content {
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}